.featured-model-card__image img {
    width: 300px;
    position: relative;
}

.integra-2024.featured-model-card__image img {
    width: 50%;
}

.featured-model-card__details {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
}

.featured-model-card__overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 100%;
    transition: top 250ms ease-in-out;
    background: rgba(0,0,0,.8);
}

.featured-model-card {
    background: black;
}

.featured-model-card:hover .featured-model-card__overlay {
    top: 0;
}

@media screen and (min-width: 375px) {
    .featured-model-card__details {
        right: 50px;
    }

    .integra-2024.featured-model-card__image img {
        width: 45%;
    }
}

@media screen and (min-width: 425px) {
    .featured-model-card__image img {
        width: 350px;
    }

    .integra-2024.featured-model-card__image img {
        width: 50%;
    }

    .featured-model-card__details {
        right: 70px;
    }
}

@media screen and (min-width: 550px) {
    .integra-2024.featured-model-card__image img {
        width: 40%;
    }
}

@media screen and (min-width: 768px) {
    .featured-model-card__image img {
        width: 650px;
    }

    .integra-2024.featured-model-card__image img {
        width: 45%;
    }

    .featured-model-card__details {
        right: 100px;
    }
}

@media screen and (min-width: 1024px) {
    .featured-model-card__image img {
        width: 800px;
    }

    .integra-2024.featured-model-card__image img {
        width: 65%;
    }

    .featured-model-card__details {
        right: 30px;
    }
}

@media screen and (min-width: 1440px) {
    .featured-model-card__image img {
        width: 900px;
    }
}