@import "../node_modules/material-design-icons//iconfont//material-icons.css";
@import './assets/styles/controls.css';
@import './assets/styles/dialog.css';

.App {
  background: #000;
}
.Sidenav {
  color: #fff;
  z-index: 99;
  right: 0;
  top: 0;
  height: 100vh;
  width: 50%;
  padding-bottom: 1.25em;
  background-color: #000;
  box-shadow: 0 1px 8px rgb(0 0 0 / 10%), 0 8px 24px rgb(0 0 0 / 15%);
  transition: .2s;
  visibility: hidden;
  opacity: 0;
  overflow: hidden;
  position: fixed;
}

.Sidenav.show{
  display: block;
  opacity: 1!important;
  visibility: visible!important;
}
.whatsap-items{
  display: flex;
  flex-direction: column;
  align-items: end;
}

.options-whatsapp-box{
  width: 200px;
  height: fit-content;
  background-color: #f1f1f1;
  margin-bottom: 10px;
}
.option-whatsap{
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Avenir Lt Pro";
}

.status-info{
  display: none;
}