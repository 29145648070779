.control-buttons {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.control-buttons svg {
    width: 7.75px;
    height: 13.5px;
}

@media screen and (min-width: 768px) {
    .control-buttons {
        width: 50px;
        height: 50px;
    }

    .control-buttons svg {
        width: 13.5px;
        height: 27px;
    }
}

@keyframes introLoad {
    from {
        clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    }
    to {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }
}

@keyframes moveDown {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}