  /* select */

  .select {
    position: relative;
    z-index: 29;
  }

  .select_custom {
    position: relative;
    min-width: 310px;
  }
  
  /* checkout */

  .checkbox:hover input ~ .checkmark {
    background-color: #f8f9fb;
  }
  
  .checkbox input:checked ~ .checkmark {
    background-color: #000;
  }
  
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  .checkbox input:checked ~ .checkmark:after {
    display: block;
  }
  
  .checkbox .checkmark:after {
    left: 9px;
    top: 6px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }

  @media only screen and (max-width: 768px) {
    .select_custom {
        min-width: 300px;
    }
  }