.popup-animation {
    animation: popup-animation 0.5s;
}

@keyframes popup-animation {
    0% {
        top: 100%;
        opacity: 0;
    }
    
    100% {
        top: 0;
        opacity: 1;
    }
}