@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @font-face {
        font-family: "Acura Bespoke";
        src: url("./assets/fonts/acura_bespoke-light.otf") format("opentype");
        font-weight: 400;
        font-style: normal;
      }
      
      @font-face {
        font-family: "Acura Bespoke";
        src: url("./assets/fonts/acura_bespoke-regular.otf") format("opentype");
        font-weight: 500;
        font-style: normal;
      }
    
    @font-face {
        font-family: "Avenir Lt Pro";
        src: url("./assets/fonts/AvenirLTPro-Light.otf") format("opentype");
        font-weight: 300;
        font-style: normal;
    }

    @font-face {
      font-family: "Avenir Lt Pro";
      src: url("./assets/fonts/AvenirLTPro-Roman.otf") format("opentype");
      font-weight: 400;
      font-style: italic;
    }

    @font-face {
        font-family: "Avenir Lt Pro";
        src: url("./assets/fonts/AvenirLTPro-Medium.otf") format("opentype");
        font-weight: 500;
        font-style: normal;
    }
    
    @font-face {
      font-family: "Avenir Lt Pro";
      src: url("./assets/fonts/AvenirLTPro-Heavy.otf") format("opentype");
      font-weight: 700;
      font-style: normal;
    }
  }
  
.phone_icon{
  width: 28px;
}
.acura_wrapper {
  margin: auto;
  max-width: 2000px;
}
.acura_wrapper_xl {
  max-width: 2000px;
}
.acura_menu { 
  position: relative; 
  width: 120px;
  text-align: center;
}
.acura_menu .acura_submenu {
  display: none;
  background: #000; 
  margin-top: 0;
  color: #fff;
}
.acura_menu .acura_submenu li {
  padding:1.1rem 0;
}
.acura_menu:hover .acura_submenu {
  display: block;
  position: absolute;
  width: 100%;
}
.commas {
  position: absolute;
  width: 79px;
  height: 68px;

  z-index: 0;
  top: 1.8rem;
  left: 7.2rem;
  opacity: .6;

}
.acura_card {
  position: relative;
  overflow: hidden;
}
.acura_card,
.acura_card_details { 
  min-height: 320px;
}
.acura_card .acura_card_overlay {
  position: absolute;
  bottom: -100%;
  background: rgba(0,0,0,.8);
  height: 100%;
  width: 100%;
}
.acura_card_img {
  display: block;
  position: relative;
  z-index: 1;
}
.acura_card_img img {
  width: 100%;
  height: auto;
  left: 0;
  position: absolute;
  top: 0;
}
.acura_card_details {
  z-index: 8;
  position: absolute;
  right: 15px;
}
.acura_card:hover .acura_card_overlay {
  display: block;
  bottom: 0;  
  left: 0;
  z-index: 9;
  transition: all 250ms ease-in;
}
.acura_card:hover .acura_card_details {
  visibility: hidden;
}

.acura_banner {
  height: 100%;
  width: 100%;
  position: absolute;
  top:0;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 100% 100%;
}

.acura_overlay {
  background: rgba(0,0,0,.4);
  position: relative;
  height: 100vh;
}
.acura_detail_col {
  min-height: 640px;
}

.card-image {
  height: 200px
}

.featured-card-wrapper {
  position: relative;
}

.featured-models {
  max-width: 2000px;
  margin: 0 auto;
}

.carousel-picture img {
  width: 100%;
}

.logo-loading {
  width: 150px;
  height: 150px;
  animation: logoLoadingAnimation 2s ease-in-out infinite;
}

.transparent-overlay {
  background: rgba(0,0,0,.5);
}

.financing-banner {
  height: 100vh;
}

@keyframes logoLoadingAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.spare-parts-banner {
  height: 75vh;
}

/*Responsive */

@media only screen and (min-width: 768px) {
  .financing-banner {
    height: 500px;
  }
  .featured-card-wrapper:nth-child(2n) > .car_card::before {
    content: "";
    position: absolute;
    width: 1px;
    height: 60%;
    background: #171717;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 20;
  }
}
@media only screen and (min-width: 1280px) {
  .acura_card_details {
    right: 40px;
  }
}
@media only screen and (max-width: 1280px) {
  .acura_card,
  .acura_card_details,
  .acura_card_img img {
    min-height: 220px;
    max-height: 220px;
  }
}
@media only screen and (max-width: 1024px) {
  .acura_banner {
    background-size: cover;
  }
}
@media only screen and (max-width: 768px) {
  .acura_card,
  .acura_card_details,
  .acura_card_img img {
    min-height: 260px;
    max-height: 260px;
  }
  .commas {
    top: 2rem;
    left: 1rem;
    width: 50px;
  }
} 
@media only screen and (max-width: 360px) {
  .acura_card,
  .acura_card_details,
  .acura_card_img img {
    min-height: 180px;
    max-height: 180px;
  }
}