.dialog {
    opacity: 0;
    visibility: hidden;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    text-align: left;
    background: rgba(0,0,0, .9);
    transition: opacity .25s ease;
    z-index: 59;
}
.dialog_content {
    min-height: 50%;
    max-height: 80%;
    width: 80%;
    margin: 0 auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 60;
    position: absolute;
    background: #000;
}
.dialog.show {
    display: block;
    opacity: 1!important;
    visibility: visible!important;
}
.dialog iframe {
    min-width: 60vw;
    height: 80vh;
}